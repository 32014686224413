import styled from 'styled-components';
import 'normalize.css';
import './App.css';
import logo from './logo.svg';
import { Calc } from './components/Calc';

setTimeout(() => {
    window.location = '/';
}, 120000);

function App() {
  return (
    <MainWrapper className='App'>
        <header>
            <img id="logo" alt="Gate Sentry Visitor Management" src={logo} />
        </header>
            {window.location.search === '' ? (
            <h3>Please scan the QR code again to open the gate.</h3>
            ) : (
                <Calc />
            )}
        <footer id="footer">
            <a id="gatelink" href="https://gatesentry.com/">GATESENTRY.COM</a>
        </footer>
    </MainWrapper>
  );
}

const MainWrapper = styled.main`
    h3 {
        margin-top: 5rem;
        text-align: center;
        color: white;
    }

    box-sizing: border-box;
    grid-area: main;
    background: #000;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 28rem;
    align-items: center;

    header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 20vh;
        max-height: 30rem;
        h3 {
            margin-top: 0.3rem;
        }
    }

    #logo {
        margin-top: 1vh;
        width: auto;
        height: 18vh;
        max-height: 16rem;
        min-height: 7rem;
    }

    #footer {
        margin-top: auto;
        padding-bottom: 0.2rem;
    }

    #gatelink {
        color: #B70100;
        font-size: 1.2em;
        text-decoration: none;
        cursor: pointer;
    }
`;



export default App;

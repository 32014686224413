import React, { useState } from 'react';
import styled from 'styled-components';
import 'normalize.css';
import axios from 'axios';
import { Display } from './Display';
import { Key } from './Key';
import { NumInput } from './NumInput';

export const Calc = ({ loc, locPermissionStatus, getLoc }) => {
    const [input, setInput] = useState('');
    const [messageActive, setMessageActive] = useState(false);
    const [error, setError] = useState('');
    window.history.forward();
    const addKey = key => {
        const handler = () => {
            if (error.length > 0) {
                setError('');
            }
            if (messageActive) {
                setMessageActive(false);
                setInput(key);
            } else {
                setInput(oldInput => {
                    if (oldInput.length < 8) {
                        return oldInput + key;
                    }

                    return oldInput;
                });
            }
        };
        return handler;
    };

    const setMessage = message => {
        setInput(message);
        setMessageActive(true);
    };

    // function getPos() {
    //     return new Promise((resolve, reject) =>
    //         navigator.geolocation.getCurrentPosition(resolve, reject)
    //     );
    // }

    const removeKey = () => {
        if (error.length > 0) {
            setError('');
        }
        if (messageActive) {
            setMessageActive(false);
            setInput('');
        } else {
            setInput(oldInput => oldInput.slice(0, -1));
        }
    };

    const submit = async () => {
        setMessage('Loading');
        const codeInput = input;
        // let position;
        // try {
        //     // position = await getPos();
        // } catch (err) {
        //     // They've refused to give the app permission, denied it before, or have not granted location permissions to their current app.
        //     alert(
        //         'Unable to get location, please ensure you have granted permission to the website and app to access your location.'
        //     );
        //     // setMessageActive(true);
        //     // setMessage('bad perms');
        //     return;
        // }
        // try {
        if (messageActive) {
            setMessageActive(false);
            setInput('');
            return;
        }
        if (codeInput.length < 4) {
            if (codeInput.length === 0) {
                setMessage('Please Enter Code');
            } else {
                setMessage('Invalid Code');
            }
        } else if (
            window.location.href.split('fX')[1]?.length < 19 ||
            window.location.href.split('fX')[1]?.length > 22
        ) {
            setMessage('Invalid QR Code');
        } else {
            setMessageActive(true);
            setMessage('Submitted');
            // const { longitude, latitude, accuracy } = position.coords;

            try {
                const keyRes = await axios.post(
                    'https://gatesentry.herokuapp.com/solo',
                    // 'https://gatesentry-staging.herokuapp.com/solo',
                    {
                        code: codeInput,
                        string: `fX${window.location.href.split('fX')[1]}`,
                        geo: { longitude: 0, latitude: 0, accuracy: 0 },
                    },
                    { timeout: 10000 }
                )
                if (keyRes.data.success) {
                    setMessage('Success');
                    setTimeout(() => {
                        window.location = '/';
                    }, 5000);
                }
            } catch(e) {
                if (!e.response) {
                    setMessage('Login Error');
                    console.log('Failed Connection to Server');
                } else if (e.response.status === 401) {
                    if (e.response.data && e.response.data.message === "Off Hours") {
                        setMessage('Off Hours');
                    } else {
                        setMessage('Invalid Code');
                    }
                } else if (e.response.status === 504) {
                    setMessage('Gate Down');
                } else if (e.response.status === 500) {
                    setMessage('Login Error');
                }
            }
        }
    }

    return (
        <CalcContainer>
            <div id="calc">
                <Display current={input} messageActive={messageActive} />
                <h2 className='locationWarning'>Location sharing required<br/>to open gate</h2>

                <div id="InputArea">
                    <NumInput addKey={addKey} />
                    <Key
                        val="Back"
                        classname="back"
                        handler={removeKey}
                        aria-label="Backspace"
                    />
                    <Key
                        classname="numKey"
                        key="0"
                        val="0"
                        handler={addKey('0')}
                    />
                    <Key
                        val="Open"
                        classname="submit"
                        aria-label="Submit"
                        handler={submit}
                    />
                </div>
            </div>
        </CalcContainer>
    );
};

const CalcContainer = styled.div`
    h2 {
        text-align: center;
        margin-bottom: 1rem;
        color: white;
    }

    // flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-sizing: border-box;
    // margin-top: 2rem;
    left: 1rem;
    right: 1rem;
    min-height: 25rem;
    z-index: 2;

    .back {
        background: var(--reject-color);
        color: #fff;
        background-color: #fff;
        color: black;
        font-weight: 900;
    }
    .submit {
        background-color: #fff;
        color: black;
        font-weight: 900;
    }
    .locationWarning {
        font-size: 1em;
    }
    #calc {
        width: 95%;
        max-width: 24rem;
        min-width: 17rem;
        padding: 1rem;
        background: #666666;
        border-radius: 1.5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        max-height: 30rem;
        min-height: 28rem;
        align-items: center;
    }

    #InputArea {
        width: 95%;
        flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        height: 80%;
        max-height: 19rem;
        justify-items: center;
        align-items: center;
        min-width: 13rem;
    }

    #InputArea div {
        aspect-ratio: 1;
    }
`;

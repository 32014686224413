import React from 'react';
import styled from 'styled-components';

export const Key = ({ handler, val, classname = '' }) => (
    <KeyStyle
        className={classname}
        variant="h3"
        align="center"
        onClick={handler}
        isNaN={isNaN(parseInt(val))}
    >
        {val}
    </KeyStyle>
);

const KeyStyle = styled.div`
    font-size: ${props => (props.isNaN ? '1.2em' : '2.2rem')};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95%;
    background: #666;
    border: 1.5px solid #fff;
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
    :hover {
        background: #fcfcfc;
        color: ${props => (props.isNaN ? '#e5e5e5' : ' #757575')};
    }
    :

    font-weight: ${props => (props.isNaN ? '500' : ' normal')};
    color: ${props => (props.isNaN ? '#e5e5e5' : '#fff')};
    font-family: 'Roboto', sans-serif;
//   line-height: 14.1vh; */
    /* min-height: 14vh; */
`;

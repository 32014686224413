import React from 'react';
import styled from 'styled-components';

export const Display = ({ current, messageActive }) => (
    <DisplayStyle messageActive={messageActive}>
        <div
            id="dis"
        >
            {current}
        </div>
    </DisplayStyle>
);

const DisplayStyle = styled.div`
    width: 100%;

    #disContainer {
        display: flex;
        justify-content: center;
    }
    #dis {
        overflow: hidden;
        background-color: white;
        margin: 0;
        height: 3rem;
        text-align: center;
        font-size: ${props => (props.messageActive ? '2.2rem' : '3rem')};
        font-weight: 400;
        width: 100%;
        max-width: var(--max-width);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 75px 75px;
    }
`;
